<template>
  <div>
    <div class="home" @click.stop="istype = false;iscategory=false;isquality=false;playAudio()">
      <div class="header_top">
        <div></div>
        <div class="top_title">{{ $t('h.rewardsStore') }}</div>
        <div @click="handleilltate();playAudio()">{{ $t('h.redemptionInstructions') }}</div>
      </div>
    </div>
    <div class="shop_center" @click.stop="istype = false;iscategory=false;isquality=false;playAudio()">
      <div class="shop_center_left" @click.stop="istype = !istype;playAudio()">
        <div>
          {{ type }}
          <i class="el-icon-arrow-down" style="font-weight: bold"></i>
        </div>
        <div class="center_left_xl" v-if="istype">
          <div
            class="xl_btn"
            :class="type_s == 'zhpx' ? 'xl_active' : ''"
            @click.stop="handleType('zhpx');playAudio()"
          >
            {{ $t('h.comprehensiveSorting') }}
          </div>
          <div
            class="xl_btn"
            :class="type_s == 'jgjx' ? 'xl_active' : ''"
            @click.stop="handleType('jgjx');playAudio()"
          >
            {{ $t('h.priceInDescendingOrder') }}
          </div>
          <div
            class="xl_btn"
            :class="type_s == 'jgsx' ? 'xl_active' : ''"
            @click.stop="handleType('jgsx');playAudio()"
          >
            {{ $t('h.priceInAscendingOrder') }}
          </div>
        </div>
      </div>
      <div class="shop_center_right">
        <input
          type="text"
          v-model="queryParams.name"
          name
          id
          style="serch"
          :placeholder="$t('h.pleaseCosmeticName')"
        />
        <svg
          @click="getshoplist();playAudio()"
          t="1705982070098"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          p-id="3285"
          width="24"
          height="24"
        >
          <path
            d="M512.034281 76.882018c-240.202711 0-435.165054 194.83136-435.165054 435.124122 0 240.268202 194.96132 435.100586 435.165054 435.100586 59.585073 0 116.371404-12.05557 168.096463-33.743522-13.106506-5.012154-24.790616-14.037714-32.602535-26.979468L544.87013 714.613604c-111.893416 33.626865-236.425687-10.772344-299.55447-115.499547-74.789357-123.859959-34.93465-285.041942 88.921215-359.814925 124.066667-74.738192 285.119713-34.814923 359.861998 89.102341 63.10627 104.58701 44.26311 235.499595-37.545104 318.843792l102.704127 171.745573c8.442276 13.96813 10.727319 29.801746 8.185426 44.703131 108.698654-79.121012 179.662893-206.981075 179.662893-351.687828C947.106214 271.713378 752.261551 76.882018 512.034281 76.882018"
            fill="#3A97E4"
            p-id="3286"
          />
          <path
            d="M385.72862 324.729849c-76.770478 46.324048-101.538581 146.152684-55.153134 223.013213 46.385447 76.905554 146.222269 101.531418 223.180012 55.149041 76.842109-46.310745 101.538581-146.233526 55.153134-223.01219C562.546721 302.902728 462.687386 278.230816 385.72862 324.729849"
            fill="#3A97E4"
            p-id="3287"
          />
        </svg>
      </div>
    </div>
    <div class="shop_center_pz_lb" @click.stop="istype = false;iscategory=false;isquality=false;playAudio()">
      <div style="display: flex">
        <div class="pz" @click.stop="isquality = !isquality;playAudio()">
          <div>
            {{ quality }}
            <i class="el-icon-arrow-down" style="font-weight: bold"></i>
          </div>
          <div class="pz_qt" v-if="isquality">
            <div
              class="center_left_xl"
              v-for="(item, index) in qualityList"
              :key="index"
            >
              <div
                class="xl_btn"
                :class="
                  queryParams.quality == Object.values(item)[0]
                    ? 'xl_active'
                    : ''
                "
                @click.stop="handleQuality(item);playAudio()"
              >
                {{ Object.keys(item)[0] }}
                <!-- {{ $t('b.' + Object.keys(item)[0]) }} -->
              </div>
            </div>
          </div>
        </div>
        <div class="pz" @click.stop="iscategory = !iscategory;playAudio()">
          <div>
            {{ category }}
            <i class="el-icon-arrow-down" style="font-weight: bold"></i>
          </div>
          <div class="pz_qt" v-if="iscategory">
            <div
              class="center_left_xl"
              v-for="(item, index) in categoryList"
              :key="index"
            >
              <div
                class="xl_btn"
                :class="
                  queryParams.category == Object.values(item)[0]
                    ? 'xl_active'
                    : ''
                "
                @click.stop="handleCategory(item);playAudio()"
              >
              {{ Object.keys(item)[0] }}
                <!-- {{ $t('b.' + Object.keys(item)[0]) }} -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="display: flex"
        @click="isworth = true;playAudio()"
        class="screening center"
      >
        <div style>
          <svg
            t="1705981767558"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="3075"
            width="100%"
            height="100%"
          >
            <path
              d="M384 0L426.666667 426.666667H85.333333zM640 1024L597.333333 597.333333h341.333334z"
              fill="#ffffff"
              p-id="3076"
            />
            <path
              d="M341.333333 0h85.333334v1024H341.333333zM597.333333 0h85.333334v1024H597.333333z"
              fill="#ffffff"
              p-id="3077"
            />
          </svg>
        </div>
        {{ $t('h.valueFiltering') }}
      </div>
    </div>

    <!--移动端-->
    <div class="shop_bottom hidden-md-and-up" @scroll="scrollEventFn" @click.stop="istype = false;iscategory=false;isquality=false;playAudio()">
      <div class="grade3">
        <div
          v-for="(item, index) in shoplist"
          :key="index"
          @click="handleOpen(item);playAudio()"
          class="weapon"
        >
          <div class="weapon_img">
            <img :src="item.imageUrl" alt />
          </div>
          <div class="textover-f">{{ item.name }}</div>
          <div class="integral-integral" style="margin-top: 4px">
            <integral class="integral"></integral>
            {{ item.creditsPrice }}
          </div>
        </div>
      </div>
      <div class="over">{{ isOver }}</div>
    </div>
    <!--PC端-->
    <div class="shop_bottom hidden-sm-and-down" @click.stop="istype = false;iscategory=false;isquality=false;playAudio()">
      <div class="grade3">
        <div
          v-for="(item, index) in shoplist"
          :key="index"
          @click="handleOpen(item);playAudio()"
          class="weapon"
        >
          <div class="weapon_img">
            <img :src="item.imageUrl" alt />
          </div>
          <div class="textover-f">{{ item.name }}</div>
          <div class="integral-integral" style="margin-top: 4px">
            <integral class="integral"></integral>
            {{ item.creditsPrice }}
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryParams.pageNum"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="queryParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <div class="exchange_bg" v-if="isopen" @click.stop="istype = false;iscategory=false;isquality=false;playAudio()">
      <div class="exchange_bg_item column">
        <div class="exchange_bg_item_title">{{ $t('h.PointsRedemption') }}</div>
        <div class="item_img">
          <div class="item_img_title">{{ list.exteriorName }}</div>
          <div class="item_img_img">
            <img :src="list.imageUrl" alt width="100%" height="100%" />
          </div>
          
        </div>
        <div class="textover-f" style="height: 30px;">{{ list.name }}</div>
        <div class="item_integral center">
          <div class="integral-integral" style="margin-top: 4px">
            <integral class="integral"></integral>
            {{ list.usePrice }}
          </div>
        </div>
        <div class="exchange_btn">
          <div class="qx" @click="handleClose();playAudio()">{{ $t('h.cancel') }}</div>
          <div class="dh" @click="handleExchange();playAudio()">{{ $t('h.exchange') }}</div>
        </div>
      </div>
    </div>
    <div class="price_bg" v-if="isworth" >
      <div class="price">
        <div class="lvbg"></div>
        <div class="price_title">{{ $t('h.valueFiltering') }}</div>
        <div class="price_input">
          <input type="text" v-model="queryParams.minPrice" />
          <div style="border-top: 1px solid #fff; width: 14px"></div>
          <input type="text" v-model="queryParams.maxPrice" />
        </div>
        <div class="price_btn space_between">
          <div class="btn_A center" @click="handleWorth(1);playAudio()">{{ $t('h.cancel') }}</div>
          <div class="btn_B center" @click="handleWorth(2);playAudio()">{{ $t('h.confirmOk') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  shoplist,
  shopconvert,
  shopcondition,
  getArticle,
  getUserInfo,
} from "@/api/index";
export default {
  data() {
    return {
      isOver: this.$i18n.t('h.loading'),
      queryParams: {
        sortBy: null,
        maxPrice: null,
        minPrice: null,
        quality: null,
        type: null,
        pageSize: 15,
        pageNum: 1,
      },
      iscategory: false,
      isworth: false,
      isPrice: true,
      qualityList: [],
      categoryList: [],
      category: this.$i18n.t('h.category'),
      isopen: false,
      isquality: false,
      quality: this.$i18n.t('h.quality'),
      pz_s: false,
      type: this.$i18n.t('h.comprehensiveSorting'),
      type_s: "zhpx",
      istype: false,
      // shoplist: [],
      item: {},
      total: 0,
      shoplist: [],
      event: null,
    };
  },
  created() {
    this.getshoplist();
    this.getshopcondition();
    window.addEventListener('popstate',()=>{
      if(this.isopen){
        this.isopen=false
      }
    })
  },
  methods: {
    playAudio(){
      this.$store.commit("playAudio");
    },
    handleilltate() {
      this.$bus.$emit("is_illustrate", true);
      setTimeout(() => {
        getArticle("shop").then((res) => {
          let dream = res.data.data.contentList[0].content;
          this.$bus.$emit("nr_illustrate", dream);
        });
      }, 500);
    },
    scrollEventFn(e) {
      // console.log(e);
      this.event = e;
      if (
        e.srcElement.scrollTop + e.srcElement.clientHeight >=
        e.srcElement.scrollHeight
      ) {
        if (this.queryParams.pageSize == this.total) {
          this.queryParams.pageSize += 15;
          setTimeout(() => {
            this.getshoplist();
          }, 5);
        } else {
          this.isOver = this.$i18n.t('h.noMoreAvailable');
        }
      }
    },
    handleWorth(res) {
      if (res == 2) {
        this.isworth = false;
        this.getshoplist();
      } else if (res == 1) {
        this.isworth = false;
        this.queryParams.maxPrice = null;
        this.queryParams.minPrice = null;
      }
    },
    handleExchange() {
      let obj = { ornamentsId: this.list.id };
      shopconvert(obj).then((res) => {
        getUserInfo().then((res) => {
          this.$store.commit("USER_INFO", res.data.data);
        });
        if (res.data.code == 200) {
          // this.isopen = false;
          this.handleClose()
          this.$message({
            message: this.$i18n.t('h.redemptionSuccessful'),
            type: "success",
            customClass: "log_success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
            customClass: "log_warning",
          });
        }
      });
    },
    handleClose(){
      this.isopen=false
      this.$router.back()
    },
    handleOpen(res) {
      this.list = {};
      this.isopen = true;
      const state = { title: "title", url: "#" };
      window.history.pushState(state, state.title, state.url);
      this.list = res;
    },
    handleCategory(res) {
      this.queryParams.pageSize = 15;
      if (this.event) {
        this.event.srcElement.scrollTop = 0;
      }
      this.queryParams.type = Object.values(res)[0];
      this.getshoplist();
      this.iscategory = false;
      this.category = Object.keys(res)[0];
      if (Object.keys(res)[0] == this.$i18n.t('h.default')) {
        this.category = this.$i18n.t('h.category');
      } else {
        this.category = Object.keys(res)[0];
      }
    },
    handleQuality(res) {
      this.queryParams.pageSize = 15;
      if (this.event) {
        this.event.srcElement.scrollTop = 0;
      }

      // console.log(res);
      this.queryParams.quality = Object.values(res)[0];
      this.getshoplist();
      this.isquality = false;
      if (Object.keys(res)[0] == this.$i18n.t('h.default')) {
        this.quality = this.$i18n.t('h.quality');
      } else {
        this.quality = Object.keys(res)[0];
      }
      // console.log('quality',this.quality);
    },

    handleType(res) {
      if (res == "zhpx") {
        this.type = this.$i18n.t('h.comprehensiveSorting');
        this.type_s = "zhpx";
        this.istype = false;
        this.queryParams.sortBy = null;
      } else if (res == "jgsx") {
        this.type = this.$i18n.t('h.priceInAscendingOrder');
        this.type_s = "jgsx";
        this.istype = false;
        this.queryParams.sortBy = 1;
      } else if (res == "jgjx") {
        this.type = this.$i18n.t('h.priceInDescendingOrder');
        this.type_s = "jgjx";
        this.istype = false;
        this.queryParams.sortBy = 2;
      }
      this.queryParams.pageSize = 15;
      this.getshoplist();
    },
    handleback() {
      alert("1");
    },
    getshoplist() {
      shoplist(this.queryParams).then((res) => {
        this.shoplist = res.data.rows;
        this.total = res.data.total;
        if (res.data.total == 0) {
          this.isOver = this.$i18n.t('h.noCosmeticsAvailable');
        }
        if (this.total <= this.queryParams.pageSize) {
          this.isOver = this.$i18n.t('h.allLoaded');
        }
      });
    },
    // PC分页
    handleSizeChange(size) {
			this.queryParams.pageSize = size;
			this.getshoplist();
    },
    handleCurrentChange(page) {
      this.queryParams.pageNum = page;
			this.getshoplist();
    },
    getshopcondition() {
      shopcondition(0).then((res) => {
        this.categoryList = res.data.data;
        this.categoryList.push({ 默认: "" });
      });
      shopcondition(3).then((res) => {
        this.qualityList = res.data.data;
        this.qualityList.push({ 默认: "" });
        console.log("qualityList", this.qualityList);
      });
    },
  },
  watch:{
    istype(newValue){
      if(newValue){
        this.iscategory=false
        this.isquality=false
      }
    },
    iscategory(newValue){
      if(newValue){
        this.istype=false
        this.isquality=false
      }
    },
    isquality(newValue){
      if(newValue){
        this.iscategory=false
        this.istype=false
      }
    }
    
  }
};
</script>

<style lang="scss" scoped>
.screening {
  font-size: 14px;
  .icon {
    width: 18px;
    height: 21px;
    margin-right: 5px;
    @media (max-width: 550px) {
      width: 13px;
      height: 15px;
    }
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
}
.over {
  margin-top: 5%;
  color: #727477;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.price_bg {
  position: fixed;
  width: 100vw;
  left: 50%;
  height: 100vh;
  // margin-left: -262.5px;
  transform: translateX(-50%);
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 550px) {
    width: 100%;
  }
  .price {
    position: relative;
    margin: 0 auto;
    // margin-top: 30%;
    width: 480px;
    height: 260px;
    background-color: #101115;
    border-radius: 21px;
    padding: 2%;
    @media (max-width: 550px) {
      width: 90%;
      padding: 3%;
      height: 175px;
    }
    .price_title {
      width: 100%;
      font-size: 22px;
      text-align: center;
      @media (max-width: 550px) {
        font-size: 18px;
      }
    }
    .price_input {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-top: 35px;
      position: relative;
      z-index: 5;
      @media (max-width: 550px) {
        margin-top: 25px;
      }
      input {
        width: 45%;
        height: 56px;
        border-radius: 35px;
        border: 1px solid #323436;
        color: #fff;
        font-size: 18px;
        background-color: #323436;
        text-indent: 10px;
        text-align: center;
        outline: none;
        @media (max-width: 550px) {
          height: 40px;
          font-size: 14px;
        }
      }
      input::-webkit-input-placeholder {
      }
    }
    .price_btn {
      position: relative;
      z-index: 5;
      margin: 0 auto;
      margin-top: 6%;
      font-size: 18px;
      .btn_A {
        width: 45%;
        height: 45px;
        background: linear-gradient(
          360deg,
          rgba(114, 116, 119, 0.5) 0%,
          rgba(114, 116, 119, 0.25) 100%
        );
        opacity: 1;
        border: 1px solid #727477;
        @media (max-width: 768px) {
          height: 36px;
          font-size: 14px;
        }
      }
      .btn_B {
        width: 45%;
        height: 45px;
        background: linear-gradient(
          360deg,
          rgba(37, 244, 132, 0.5) 0%,
          rgba(37, 244, 132, 0.25) 100%
        );
        opacity: 1;
        border: 1px solid #25f484;
        @media (max-width: 768px) {
          height: 36px;
          font-size: 14px;
        }
      }
    }
  }
  .price::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      -219deg,
      rgba(37, 244, 132, 0.2) 0%,
      rgba(32, 29, 48, 0) 35%,
      rgba(248, 125, 81, 0) 100%
    );
    border-radius: 21px;
    opacity: 1;
  }
}
.serch {
  height: 100%;
  width: 70%;
}
.exchange_btn {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3%;
  .qx {
    width: 185px;
    height: 42px;
    // border-radius: 35px 35px 35px 35px;
    opacity: 1;
    background: linear-gradient(
      360deg,
      rgba(114, 116, 119, 0.5) 0%,
      rgba(114, 116, 119, 0.25) 100%
    );
    border: 1px solid #727477;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 550px) {
      width: 140px;
      height: 30px;
      font-size: 15px;
    }
  }
  .dh {
    width: 185px;
    height: 42px;
    background: linear-gradient(
      360deg,
      rgba(37, 244, 132, 0.5) 0%,
      rgba(37, 244, 132, 0.25) 100%
    );
    opacity: 1;
    border: 1px solid #25f484;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 550px) {
      width: 140px;
      height: 30px;

      font-size: 15px;
    }
  }
}
.shop_center_pz_lb {
  width: 95%;
  margin: 0 auto;
  margin-top: 7px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  .pz {
    width: 112px;
    height: 42px;
    background: #323436;
    border-radius: 70px 70px 70px 70px;
    opacity: 1;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 3%;
    font-size: 14px;
    @media (max-width: 768px) {
      width: 81px;
      height: 30px;
      font-size: 12px;
    }

    .pz_qt {
      position: absolute;
      top: 49px;
      width: 100%;
      background: #101115;
      border-radius: 14px 14px 14px 14px;
      opacity: 1;
      padding: 1px;
      @media (max-width: 550px) {
        top: 39px;
      }
      .xl_btn {
        width: 100%;
        height: 42px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 550px) {
          height: 30px;
        }
      }

      .xl_active {
        background: linear-gradient(
          360deg,
          rgba(37, 244, 132, 0.5) 0%,
          rgba(37, 244, 132, 0.25) 100%
        );
        border: 1px solid #25f484;
      }
    }
  }
  .lb {
    width: 112px;
    height: 42px;
    background: #323436;
    border-radius: 70px 70px 70px 70px;
    opacity: 1;
    position: relative;
  }
}
.exchange_bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 50%;
  background: rgba(0, 0, 0, 0.85);
  top: 0;
  z-index: 10;
  // margin-left: -262.5px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  .exchange_bg_item {
    width: 480px;
    height: 448px;
    margin: 0 auto;
    padding: 32px;
    background: #101115;
    border-radius: 21px 21px 21px 21px;
    opacity: 1;
    position: relative;

    @media (max-width: 768px) {
      width: 95%;
      height: 360px;
      padding: 23px;
    }
    .exchange_bg_item_title {
      font-size: 24px;
      @media (max-width: 550px) {
        font-size: 20px;
      }
    }

    .item_img {
      width: 210px;
      height: 224px;
      background: rgba(194, 61, 212, 0.25) url("../../assets/red.png");
      border-radius: 13px;
      background-size: 100% 100%;
      opacity: 1;
      border: 1px solid #c23dd4;
      margin: 0 auto;
      margin-top: 24px;
      @media (max-width: 550px) {
        width: 150px;
        height: 160px;
        padding-top: 9px;
        font-size: 12px;
      }
      .item_img_title {
        @media (max-width: 550px) {
          font-size: 13px;
        }
      }
      .item_img_img {
        width: 144px;
        height: 144px;
        margin: 0 auto;
        @media (max-width: 550px) {
          width: 103px;
          height: 103px;
        }
      }
    }
    .item_integral {
      height: 56px;
      margin-top: 14px;

      width: 96%;
      background: #323436;
      border-radius: 25px 25px 25px 25px;
      opacity: 1;
      @media (max-width: 550px) {
        margin-top: 10px;
        height: 40px;
      }
    }
  }
  // .exchange_bg_item:before {
  //   content: "";
  //   border-radius: 21px 21px 21px 21px;
  //   width: 100%;
  //   height: 100%;
  //   background: linear-gradient(
  //     -219deg,
  //     rgba(37, 244, 132, 0.2) 0%,
  //     rgba(32, 29, 48, 0) 40%,
  //     rgba(248, 125, 81, 0) 100%
  //   );
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  // }
}
.shop_bottom {
  width: 95%;
  margin: 0 auto;
  margin-top: 14px;
  // display: flex;
  // align-items: center;
  // justify-content: left;
  // flex-wrap: wrap;
  height: 580px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .weapon {
    width: 100%;
    height: 175px;
    // margin-top: 4px;
    background: rgba(50, 52, 54, 0.25) url("../../assets/red.png");
    background-size: 100% 100%;
    border-radius: 14px 14px 14px 14px;
    padding: 8px 5%;
    margin: 1px;
    @media (max-width: 550px) {
      height: 125px;
      padding: 6px 5%;

      font-size: 9px;
    }
    .weapon_img {
      width: 100%;
      height: 108.19px;
      @media (max-width: 550px) {
        width: 93%;
        margin: 0 auto;
        height: 83.77px;
      }
      img {
        @media (max-width: 550px) {
          width: 100%;
          height: 100%;
        }
        width: 144.25px;
        height: 108.19px;
      }
    }
  }
}

.shop_center {
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .shop_center_left {
    // width: 140px;
    height: 42px;
    background: #323436;
    border-radius: 70px 70px 70px 70px;
    opacity: 1;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 15px;
    @media (max-width: 768px) {
      // width: 100px;
      height: 30px;
      font-size: 11px;
    }

    .center_left_xl {
      position: absolute;
      width: 100%;
      background: #101115;
      opacity: 1;
      top: 47px;
      animation: xl 0.2s linear;
      overflow: hidden;
      z-index: 10;
      @media (max-width: 550px) {
        top: 37px;

        animation: xlsj 0.2s linear;
      }

      .xl_btn {
        width: 100%;
        height: 42px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 550px) {
          height: 30px;
        }
      }

      .xl_active {
        background: linear-gradient(
          360deg,
          rgba(37, 244, 132, 0.5) 0%,
          rgba(37, 244, 132, 0.25) 100%
        );
        border: 1px solid #25f484;
      }
    }
  }

  .shop_center_right {
    padding: 0 5%;
    width: 344px;
    height: 42px;
    background: #323436;
    border-radius: 70px 70px 70px 70px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 550px) {
      width: 246px;
      height: 30px;

      font-size: 11px;
    }
    input {
      width: 100%;
      height: 100%;
      border-radius: 35px;
      border: 1px solid #323436;
      color: #fff;
      font-size: 15px;
      background-color: #323436;
      text-indent: 10px;
      outline: none;
      @media (max-width: 550px) {
        font-size: 11px;
      }
    }
  }
}

@keyframes xlsj {
  0% {
    height: 0;
  }

  50% {
    height: 40px;
  }

  100% {
    height: 90px;
  }
}
@keyframes xl {
  0% {
    height: 0;
  }

  50% {
    height: 70px;
    @media (max-width: 550px) {
      height: 40px;
    }
  }

  100% {
    height: 140px;
    @media (max-width: 550px) {
      height: 90px;
    }
  }
}

.home {
  margin: 0 auto;
  width: 95%;

  .header_top {
    width: 100%;
    height: 56px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div:first-child {
      background-color: #fff;
      color: #000;
      width: 56px;
      height: 21px;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      font-size: 12px;
      line-height: 21px;
      opacity: 0;
    }

    div:last-child {
      color: #fff;
      font-size: 12px;
      text-decoration: underline;
      line-height: 1.5;
      opacity: 1;
    }
  }
}
</style>
